import { useState } from "react";
import {
  errorMessages,
  warningMessages,
} from "../../../messages/commonMessages";
import { useLlaveService } from "../../../../service/Llave";

export const useFormulario = (onSave, onCancel, selectedRow, render) => {
  const [tipoBusqueda, setTipoBusqueda] = useState(
    selectedRow !== "" ? selectedRow[0].tipBusqueda : ""
  );
  const [tipoLlave, setTipoLlave] = useState(
    selectedRow !== "" ? selectedRow[0].tipLlave : ""
  );
  const [codigoInterno, setCodigoInterno] = useState(
    selectedRow !== "" ? selectedRow[0].codInterno : ""
  );
  const [descripcion, setDescripcion] = useState(
    selectedRow !== "" ? selectedRow[0].dscLlave : ""
  );
  const [alertForm, setAlertForm] = useState(null);

  const [loading, setLoading] = useState(false);

  const { post, put } = useLlaveService();

  let values = {};

  const addLlave = async (values) => {
    return await post(values);
  };

  const updateLlave = async (idLlave, values) => {
    return await put(idLlave, values);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let result;
    if (tipoBusqueda && tipoLlave && codigoInterno && descripcion) {
      setLoading(true);

      values.tipBusqueda = tipoBusqueda;
      values.tipLlave = tipoLlave;
      values.codInterno = codigoInterno;
      values.dscLlave = descripcion;

      if (render === "Add") {
        result = await addLlave(values);
      } else {
        let idLlave = selectedRow[0].idLlave;
        if (idLlave !== undefined) {
          result = await updateLlave(idLlave, values);
        }
      }

      setLoading(false);

      if (result !== undefined) {
        if (result?.success) {
          onSave();
          setTipoBusqueda("");
          setTipoLlave("");
          setCodigoInterno("");
          setDescripcion("");
        } else {
          if (result.message !== errorMessages.SERVER_ERROR) {
            showAlert("warning", "Advertencia", result.message);
          } else {
            showAlert("error", "Error", result.message);
          }
        }
      } else {
        showAlert("error", "Error", errorMessages.SERVER_ERROR);
      }
    } else {
      showAlert("error", "Error", errorMessages.FORM_ERROR);
    }
  };

  const showAlert = (severity, title, children) => {
    setAlertForm({ severity, title, children });

    setTimeout(() => {
      setAlertForm(null);
    }, 5000);
  };

  const onChangeTipoBusqueda = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setTipoBusqueda(value);
    }
  };

  const onChangeTipoLlave = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setTipoLlave(value);
    }
  };

  const onChangeCodigoInterno = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setCodigoInterno(value);
    }
  };

  const onChangeDescripcion = async ({ target }) => {
    const { value, maxLength } = target;

    if (value.length >= maxLength) {
      showAlert(
        "warning",
        "Advertencia",
        warningMessages.MAX_LENGTH(maxLength)
      );
    } else {
      setDescripcion(value);
    }
  };

  return {
    onChangeTipoBusqueda,
    onChangeTipoLlave,
    onChangeCodigoInterno,
    onChangeDescripcion,
    handleSubmit,
    tipoBusqueda,
    tipoLlave,
    codigoInterno,
    descripcion,
    alertForm,
    loading,
  };
};
