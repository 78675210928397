import Tooltip from "@mui/material/Tooltip";
import { useFormulario } from "./useFormulario.js";
import { Alert } from "../../../messages/Alert.jsx";
import Spinner from "../../../spinner/LoadingSpinner.js";

export const Formulario = ({
  onSave,
  onCancel,
  selectedRow,
  render,
  mapFacturadores = [],
}) => {
  const {
    onChangeNombre,
    onChangeFacturador,
    onChangeCodigoInterno,
    handleSubmit,
    tipoFacturador,
    nombre,
    codigoInterno,
    alertForm,
    loading,
  } = useFormulario(onSave, selectedRow, render);

  return (
    <form onSubmit={handleSubmit}>
      {alertForm && (
        <Alert
          severity={alertForm.severity}
          title={alertForm.title}
          children={alertForm.children}
          isForm={true}
        />
      )}
      {loading && <Spinner />}
      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Código Interno:
          </label>
          <Tooltip title="Código Interno">
            <input
              className="input-form"
              type="text"
              placeholder="Código Interno"
              value={codigoInterno || ""}
              onChange={onChangeCodigoInterno}
              disabled={render === "View" ? true : false}
              maxLength="50"
            />
          </Tooltip>
        </div>
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Facturador:
          </label>
          <Tooltip title="Facturador">
            <select
              id="input-facturador"
              className="select-form"
              value={tipoFacturador || ""}
              onChange={onChangeFacturador}
              disabled={render === "View" ? true : null}
            >
              <option value="">Seleccione el facturador</option>
              {mapFacturadores.map((option, index) => (
                <option key={index} value={option.idFacturador}>
                  {option.nomFacturador}
                </option>
              ))}
            </select>
          </Tooltip>
        </div>
      </div>
      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Nombre Oficina:
          </label>
          <Tooltip title="Nombre">
            <input
              id="input-nombre"
              className="input-form"
              placeholder="Nombre"
              type="text"
              value={nombre || ""}
              onChange={onChangeNombre}
              disabled={render === "View" ? true : null}
              maxLength="100"
            />
          </Tooltip>
        </div>
      </div>
      <div className="fila-form">
        <div className="button-form">
          {render !== "View" && (
            <Tooltip title="Aceptar">
              <button type="submit" id="btn-accion" className="action-button">
                Aceptar
              </button>
            </Tooltip>
          )}
          <Tooltip title="Cancelar">
            <button
              id="btn-cancelar"
              className="action-button"
              onClick={onCancel}
            >
              Cancelar
            </button>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};
