import React from "react";
import "./Alert.jsx";
import Tooltip from "@mui/material/Tooltip";
import Spinner from "../spinner/LoadingSpinner.js";
import { AlertDialogParam } from "./AlertDialogParam.jsx";

const AlertDialog = ({
  question,
  message,
  params = [], // Format [{name: "Valor", value: "Valor"}]
  loading = false,
  isOpen,
  onClose,
  onAccept,
  onCancel,
}) => {
  return (
    <div>
      {isOpen && (
        <div className="custom-dialog-overlay">
          <div className="custom-dialog-content">
            {loading && <Spinner />}
            <span onClick={onClose} className="close-button">
              &times;
            </span>
            <h3>{question}</h3>
            <hr />
            <p>{message}</p>
            {params.length !== 0 &&
              params.map(({ name, value }, index) => (
                <AlertDialogParam key={index} name={name} value={value} />
              ))}
            <div className="button-container">
              <Tooltip title="Sí">
                <button className="action-button" onClick={onAccept}>
                  Sí
                </button>
              </Tooltip>
              <Tooltip title="No">
                <button className="action-button" onClick={onCancel}>
                  No
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { AlertDialog };
