import Spinner from "../../spinner/LoadingSpinner.js";
import { useLlavesConsulta } from "./UseLlavesConsulta.js";
import { DataTable } from "../../Table/Table.jsx";
import { Footer } from "../../Footer/Footer.jsx";
import { confirmationMessages } from "../../messages/commonMessages.jsx";
import { CustomDialog } from "../../messages/CustomDialog.jsx";
import "../../../App.css";
import { AlertDialog } from "../../messages/AlertDialog.jsx";
import Tooltip from "@mui/material/Tooltip";
import { Formulario } from "./Formulario/Formulario.jsx";
import { useEffect } from "react";
import { Alert } from "../../messages/Alert.jsx";

export const MantenimientoLlavesConsulta = () => {
  const {
    handleRowSelectionChange,
    handleLlaveConsultaSearch,
    handleSave,
    handleAdd,
    handleConfirmationDelete,
    handleCancel,
    handleCloseDialog,
    onChangeTipoBusqueda,
    onChangeTipoLlave,
    onChangeDescripcion,
    getListaLlavesConsulta,
    isDialogOpen,
    isAdd,
    isEdit,
    isView,
    isDelete,
    loading,
    selectedRow,
    data,
    columnas,
    tipoBusqueda,
    tipoLlave,
    descripcion,
    alert,
  } = useLlavesConsulta();

  useEffect(() => {
    getListaLlavesConsulta();
  }, []);

  return (
    <>
      {alert && (
        <Alert
          severity={alert.severity}
          title={alert.title}
          children={alert.children}
        />
      )}

      <div className="content-wrapper">
        {loading && !isDelete && <Spinner />}
        <div className="wrapper-card">
          <h1>Mantenimiento Llaves de Consulta</h1>
          <div className="form-container">
            <div className="content">
              <div className="form-container">
                <div className="title">Parámetros</div>
                <div className="content-filter">
                  <div className="fila-form">
                    <div className="columna-form">
                      <label>Tipo de Búsqueda:</label>
                      <Tooltip title="Tipo de Búsqueda">
                        <input
                          className="input-form"
                          type="text"
                          value={tipoBusqueda}
                          onChange={onChangeTipoBusqueda}
                          name="tipoBusqueda"
                        />
                      </Tooltip>
                    </div>
                    <div className="columna-form">
                      <label>Tipo Llave:</label>
                      <Tooltip title="Tipo Llave">
                        <input
                          className="input-form"
                          type="text"
                          value={tipoLlave}
                          onChange={onChangeTipoLlave}
                          name="tipoLlave"
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <div className="fila-form">
                    <div className="columna-form">
                      <label>Descripción:</label>
                      <Tooltip title="Descripcion">
                        <input
                          className="input-form"
                          type="text"
                          value={descripcion}
                          onChange={onChangeDescripcion}
                          name="descripcion"
                        />
                      </Tooltip>
                    </div>
                    <div className="columna-form"></div>
                  </div>
                  <div className="fila-form">
                    <div
                      className="button-filter"
                      style={{ textAlign: "right" }}
                    >
                      <Tooltip title="Buscar">
                        <button
                          className="action-button"
                          onClick={handleLlaveConsultaSearch}
                        >
                          Buscar
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-container">
                <div className="title">Lista de Llaves de Consulta</div>
                <div className="content">
                  <DataTable
                    data={
                      data && Array.isArray(data)
                        ? data.map((row) => ({ id: row.idLlave, ...row }))
                        : []
                    }
                    columns={columnas}
                    onRowSelectionChange={handleRowSelectionChange}
                  ></DataTable>
                  <div className="button-action">
                    <Tooltip title="Agregar">
                      <button
                        id="btn-agregar-dialog"
                        className="action-button"
                        onClick={handleAdd}
                      >
                        Agregar
                      </button>
                    </Tooltip>

                    {isDialogOpen && isAdd && (
                      <CustomDialog
                        question={"Agregar Llave Consulta"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"Add"}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}

                    {isDialogOpen && isEdit && (
                      <CustomDialog
                        question={"Modificar Llave Consulta"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"Edit"}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}

                    {isDialogOpen && isView && (
                      <CustomDialog
                        question={"Consultar Llave Consulta"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"View"}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}

                    {isDialogOpen && isDelete && (
                      <AlertDialog
                        question="Confirmación de Eliminación"
                        message={
                          confirmationMessages.DELETE_CONFIRMATION_LLAVECONSULTA
                        }
                        params={[
                          {
                            name: "Llave de consulta",
                            value:
                              selectedRow !== undefined
                                ? selectedRow[0].tipLlave
                                : "",
                          },
                        ]}
                        loading={loading}
                        isOpen={isDialogOpen}
                        onClose={handleCancel}
                        onAccept={() =>
                          handleConfirmationDelete(selectedRow[0].idLlave)
                        }
                        onCancel={handleCancel}
                      />
                    )}
                    {/* <button
                      className="action-button"
                      onClick={handleGenerateReport}
                    >
                      Generar Reporte
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
