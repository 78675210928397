import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import { successMessages, errorMessages } from '../../messages/commonMessages.jsx';
import { useCanalesService } from '../../../service/canal.js';

export const useCanal = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isAdd, setIsAdd] = useState();
    const [isEdit, setIsEdit] = useState();
    const [isView, setIsView] = useState();
    const [isDelete, setIsDelete] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [data, setData] = useState([]);
    const [alert, setAlert] = useState(null);
    const { get, patch } = useCanalesService();

    const getCanales = async () => {
        get().then((data) => {
            setData(data);
        })
    }

    const disableCanal = async (idCanal) => {
        patch(idCanal);
    }

    const handleSave = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        getCanales();
        showAlert("success", "Éxito", successMessages.CHANGES_SAVED);
    };
    const handleAdd = () => {
        setIsDialogOpen(true);
        setIsAdd(true);
        setSelectedRow('');
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
    };
    const handleCancel = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsView(false);
        setIsDelete(false);
    };
    const handleConsult = () => {
        setIsDialogOpen(true);
        setIsView(true);
    };

    const handleEdit = () => {
        setIsDialogOpen(true);
        setIsEdit(true);
    };
    const handleDelete = () => {
        setIsDialogOpen(true);
        setIsDelete(true);
    };
    const handleGenerateReport = () => {
        showAlert("success", "Éxito", successMessages.DOWNLOAD_SUCCESSFUL);
    };
    const handleDeleteData = () => {
        handleCloseDialog();
        setIsEdit(false);
        setIsAdd(false);
        setIsDelete(false);
        disableCanal(selectedRow[0].idCanal, selectedRow)
            .then(() => {
                return getCanales();
            })
            .catch((error) => {
                console.error("Error al eliminar o recargar datos:", error);
                showAlert("error", "Error", errorMessages.DELETE_ERROR);
            });
        getCanales();
        showAlert("success", "Éxito", successMessages.DELETE_SUCCESSFUL);
    };
    const showAlert = (severity, title, message) => {
        setAlert({ severity, title, message });
        setTimeout(() => {
            setAlert(null);
        }, 3000);
    };

    const handleRowSelectionChange = (selectedRowIds, data) => { //Cuando se selecciona algún elemento
        console.log(`SelectedRowIds + ${selectedRowIds}`); //selectedRowIds es el is del canal
        const selectedIDs = new Set(selectedRowIds);
        const selectedRow = data.filter((row) =>
            selectedIDs.has(row.id),
        );
        setSelectedRow(selectedRow);
        if (selectedRow.length > 0) {
            console.log(selectedRow[0].dscCanal);
        }
    };
    const columnas = [
        { field: 'idCanal', headerName: 'Consecutivo', flex: 1 },
        { field: 'nomCanal', headerName: 'Nombre Canal', flex: 1 },
        { field: 'dscCanal', headerName: 'Descripción', flex: 1 },
        {
            field: 'acciones', headerName: 'Acciones', flex: 1,
            renderCell: (params) => (
                <div className='button-action'>
                    <Tooltip title="Consultar">
                        <button className="button-table" onClick={handleConsult}>
                            <SearchIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Modificar">
                        <button className="button-table" onClick={handleEdit}>
                            <EditIcon />
                        </button>
                    </Tooltip>
                    <Tooltip title="Eliminar">
                        <button className="button-table" onClick={handleDelete}>
                            <DeleteIcon />
                        </button>
                    </Tooltip>
                </div>
            ),
        },
    ];
    return (
        {
            handleRowSelectionChange, getCanales, handleSave, handleCloseDialog,
            handleDeleteData, columnas, isDialogOpen, isEdit, isView, isAdd,
            handleGenerateReport, handleAdd, handleCancel, alert, isDelete, selectedRow, data
        }
    )
};