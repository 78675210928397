import Tooltip from "@mui/material/Tooltip";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState, useEffect } from "react";
import {
  errorMessages,
  successMessages,
} from "../../messages/commonMessages.jsx";
import { useLlaveService } from "../../../service/Llave.js";

export const useLlavesConsulta = () => {
  const [tipoBusqueda, setTipoBusqueda] = useState("");
  const [tipoLlave, setTipoLlave] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isAdd, setIsAdd] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isView, setIsView] = useState();
  const [isDelete, setIsDelete] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const { getLlavesConsulta, getLlavesConsultaBusqueda, patch } =
    useLlaveService();

  useEffect(() => {
    if (isSearch) {
      getListaLlavesConsulta();
    }

    return () => {
      setIsSearch(false);
    };
  }, [isSearch]);

  const getListaLlavesConsulta = async () => {
    try {
      setLoading(true);
      if (tipoBusqueda || tipoLlave || descripcion) {
        await getLlavesConsultaBusqueda(
          tipoBusqueda,
          tipoLlave,
          descripcion
        ).then((response) => {
          if (Array.isArray(response)) {
            setData(response);
          } else {
            showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
          }
        });
      } else {
        await getLlavesConsulta().then((response) => {
          if (Array.isArray(response)) {
            setData(response);
          } else {
            showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
          }
        });
      }
    } catch (error) {
      showAlert("error", "Error", errorMessages.ERROR_LOADING_DATA);
    } finally {
      setLoading(false);
    }
  };

  const deleteLlave = async (idLlave) => {
    return await patch(idLlave);
  };

  const showAlert = (severity, title, children) => {
    setAlert({ severity, title, children });

    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleSave = async () => {
    handleCloseDialog();
    showAlert("success", "Exitoso", successMessages.CHANGES_SAVED);
    setIsSearch(true);
  };

  const handleAdd = () => {
    setIsDialogOpen(true);
    setIsAdd(true);
    setSelectedRow("");
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEdit(false);
    setIsAdd(false);
    setIsView(false);
    setIsDelete(false);
  };

  const handleCancel = () => {
    setLoading(false);
    handleCloseDialog();
  };

  const handleConsult = () => {
    setIsDialogOpen(true);
    setIsView(true);
  };

  const handleEdit = () => {
    setIsDialogOpen(true);
    setIsEdit(true);
  };

  const handleDelete = async () => {
    setIsDialogOpen(true);
    setIsDelete(true);
  };

  const handleConfirmationDelete = async (idLlave) => {
    if (idLlave !== undefined) {
      setLoading(true);
      const result = await deleteLlave(idLlave);
      handleCancel();
      if (result !== undefined) {
        if (result?.success) {
          showAlert("success", "Exitoso", successMessages.DELETE_SUCCESSFUL);
          setIsSearch(true);
        } else {
          showAlert("error", "Error", result.message);
        }
      } else {
        showAlert("error", "Error", errorMessages.SERVER_ERROR);
      }
    } else {
      showAlert("error", "Error", errorMessages.OPERATION_PROBLEM);
    }
  };

  const handleRowSelectionChange = (selectedRowIds, data) => {
    const selectedIDs = new Set(selectedRowIds);
    const selectedRow = data.filter((row) => selectedIDs.has(row.id));
    setSelectedRow(selectedRow);
  };

  const handleLlaveConsultaSearch = async () => {
    setIsSearch(true);
  };

  const onChangeTipoBusqueda = async ({ target }) => {
    const { value } = target;
    setTipoBusqueda(value);
  };

  const onChangeTipoLlave = async ({ target }) => {
    const { value } = target;
    setTipoLlave(value);
  };

  const onChangeDescripcion = async ({ target }) => {
    const { value } = target;
    setDescripcion(value);
  };

  const columnas = [
    { field: "codInterno", headerName: "Código Interno", flex: 1 },
    { field: "tipBusqueda", headerName: "Tipo Busqueda", flex: 1 },
    { field: "tipLlave", headerName: "Tipo Llave", flex: 1 },
    { field: "dscLlave", headerName: "Descripción", flex: 1 },
    {
      field: "acciones",
      headerName: "Acciones",
      flex: 1,
      renderCell: (params) => (
        <div className="button-action">
          <Tooltip title="Consultar">
            <button
              id="btn-consultar"
              className="button-table"
              onClick={handleConsult}
            >
              <SearchIcon />
            </button>
          </Tooltip>
          <Tooltip title="Modificar">
            <button
              id="btn-editar"
              className="button-table"
              onClick={handleEdit}
            >
              <EditIcon />
            </button>
          </Tooltip>
          <Tooltip title="Eliminar">
            <button
              id="btn-eliminar"
              className="button-table"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  return {
    handleRowSelectionChange,
    handleLlaveConsultaSearch,
    handleSave,
    handleAdd,
    handleConfirmationDelete,
    handleCancel,
    handleCloseDialog,
    onChangeTipoBusqueda,
    onChangeTipoLlave,
    onChangeDescripcion,
    getListaLlavesConsulta,
    isDialogOpen,
    isAdd,
    isEdit,
    isView,
    isDelete,
    isSearch,
    loading,
    selectedRow,
    data,
    columnas,
    tipoBusqueda,
    tipoLlave,
    descripcion,
    alert,
  };
};
