import { Tooltip } from "@mui/material";
import { useFormulario } from "./UseFormulario.js";
import { Alert } from "../../../messages/Alert.jsx";
import Spinner from "../../../spinner/LoadingSpinner.js";

export const Formulario = ({ onSave, onCancel, selectedRow, render }) => {
  const {
    onChangeTipoBusqueda,
    onChangeTipoLlave,
    onChangeCodigoInterno,
    onChangeDescripcion,
    handleSubmit,
    tipoBusqueda,
    tipoLlave,
    codigoInterno,
    descripcion,
    alertForm,
    loading,
  } = useFormulario(onSave, onCancel, selectedRow, render);

  return (
    <form onSubmit={handleSubmit}>
      {alertForm && (
        <Alert
          severity={alertForm.severity}
          title={alertForm.title}
          children={alertForm.children}
          isForm={true}
        />
      )}
      {loading && <Spinner />}
      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Código Interno:
          </label>
          <Tooltip title="Código Interno">
            <input
              className="input-form"
              type="text"
              value={codigoInterno}
              placeholder="Código Interno"
              onChange={onChangeCodigoInterno}
              disabled={render === "View" ? true : false}
              maxLength="50"
            />
          </Tooltip>
        </div>
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Tipo de Búsqueda:
          </label>
          <Tooltip title="Tipo de Búsqueda">
            <input
              className="input-form"
              type="text"
              value={tipoBusqueda}
              placeholder="Tipo de Búsqueda"
              onChange={onChangeTipoBusqueda}
              disabled={render === "View" ? true : false}
              maxLength="10"
            />
          </Tooltip>
        </div>
      </div>
      <div className="fila-form">
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Tipo Llave:
          </label>
          <Tooltip title="Tipo Llave">
            <input
              className="input-form"
              type="text"
              value={tipoLlave}
              placeholder="Tipo Llave"
              onChange={onChangeTipoLlave}
              disabled={render === "View" ? true : false}
              maxLength="5"
            />
          </Tooltip>
        </div>
        <div className="columna-form">
          <label className={render !== "View" ? "required" : ""}>
            Descripción:
          </label>
          <Tooltip title="Descripcion">
            <input
              className="input-form"
              type="text"
              value={descripcion}
              placeholder="Descripcion"
              onChange={onChangeDescripcion}
              disabled={render === "View" ? true : false}
              maxLength="100"
            />
          </Tooltip>
        </div>
      </div>
      <div className="fila-form">
        <div className="button-form">
          {render !== "View" && (
            <Tooltip title="Aceptar">
              <button id="btn-accion" className="action-button">
                Aceptar
              </button>
            </Tooltip>
          )}
          <Tooltip title="Cancelar">
            <button
              id="btn-cancelar"
              className="action-button"
              onClick={onCancel}
            >
              Cancelar
            </button>
          </Tooltip>
        </div>
      </div>
    </form>
  );
};
