import api from "./api";
export const useConectividadApi = () => {
  /**
   * @methodApi serverData.
   * @description  Método encargado de llamar al server
   */
  const serverData = async (options) => {
    try {
      let response = await api.request(options);
      switch (response.status) {
        case 200:
          return response.data;
        case 201:
          return response.data;
        case 400:
          return "error 400";
        case 404:
          return "error 404";
        case 401:
          return "error 401";
        default:
          return "error 500";
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
  return {
    //Api
    serverData,
  };
};
