import React from "react";

export const AlertDialogParam = ({ name, value }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <p>
        <span style={{ fontWeight: "bold" }}>
          {name}: {"\u00A0"}
        </span>
        {value}
      </p>
    </div>
  );
};
