import { useEffect } from "react";
import { DataTable } from "../../Table/Table.jsx";
import { Footer } from "../../Footer/Footer.jsx";
import { confirmationMessages } from "../../messages/commonMessages.jsx";
import { CustomDialog } from "../../messages/CustomDialog.jsx";
import "../../../App.css";
import { AlertDialog } from "../../messages/AlertDialog.jsx";
import Tooltip from "@mui/material/Tooltip";
import { Formulario } from "./Formulario/Formulario.jsx";
import { useOficina } from "./useOficina.js";
import Spinner from "../../spinner/LoadingSpinner.js";
import { Alert } from "../../messages/Alert.jsx";

export const MantenimientoOficina = () => {
  const {
    handleRowSelectionChange,
    handleOficinaSearch,
    handleGenerateReport,
    handleSave,
    handleConfirmationDelete,
    handleAdd,
    handleCancel,
    handleCloseDialog,
    onChangeFacturador,
    getListaOficinas,
    getListaFacturadores,
    isDialogOpen,
    isEdit,
    isView,
    isAdd,
    isDelete,
    isSearch,
    loading,
    selectedRow,
    data,
    columnas,
    tipoFacturador,
    facturadoresMap,
    alert,
  } = useOficina();

  useEffect(() => {
    getListaOficinas();
    getListaFacturadores();
  }, []);

  return (
    <>
      {alert && (
        <Alert
          severity={alert.severity}
          title={alert.title}
          children={alert.children}
        />
      )}
      <div className="content-wrapper">
        {loading && !isDelete && <Spinner />}
        <div className="wrapper-card">
          <h1>Mantenimiento de Oficinas</h1>
          <div className="form-container">
            <div className="content">
              <div className="form-container">
                <div className="title">Parámetros</div>
                <div className="content-filter">
                  <div className="fila-form">
                    <div className="columna-form">
                      <label>Facturador:</label>
                      <Tooltip title="Facturador">
                        <select
                          className="select-form"
                          value={tipoFacturador}
                          onChange={onChangeFacturador}
                        >
                          <option value="">Seleccione el facturador</option>
                          {facturadoresMap &&
                            facturadoresMap.map((option) => (
                              <option
                                key={option.idFacturador}
                                value={option.idFacturador}
                              >
                                {option.nomFacturador}
                              </option>
                            ))}
                        </select>
                      </Tooltip>
                    </div>
                    <div className="columna-form"></div>
                  </div>
                  <div className="fila-form">
                    <div
                      className="button-filter"
                      style={{ textAlign: "right" }}
                    >
                      <Tooltip title="Buscar">
                        <button
                          className="action-button"
                          onClick={handleOficinaSearch}
                        >
                          Buscar
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-container">
                <div className="title">Lista de Oficinas</div>
                <div className="content">
                  <DataTable
                    data={
                      data && Array.isArray(data)
                        ? data.map((row) => ({
                            id: row.idOficina,
                            nombre: row.nomOficina,
                            tipoFacturador: row.facturador.nomFacturador,
                            ...row,
                          }))
                        : []
                    }
                    columns={columnas}
                    onRowSelectionChange={handleRowSelectionChange}
                  ></DataTable>
                  <div className="button-action">
                    <Tooltip title="Agregar">
                      <button className="action-button" onClick={handleAdd}>
                        Agregar
                      </button>
                    </Tooltip>

                    {isDialogOpen && isAdd && (
                      <CustomDialog
                        question={"Agregar Oficina"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"Add"}
                            mapFacturadores={facturadoresMap}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}

                    {isDialogOpen && isEdit && (
                      <CustomDialog
                        question={"Modificar Oficina"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"Edit"}
                            mapFacturadores={facturadoresMap}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}

                    {isDialogOpen && isView && (
                      <CustomDialog
                        question={"Consultar Oficina"}
                        message={
                          <Formulario
                            onSave={handleSave}
                            onCancel={handleCancel}
                            selectedRow={selectedRow}
                            render={"View"}
                            mapFacturadores={facturadoresMap}
                          />
                        }
                        onClose={handleCloseDialog}
                      />
                    )}
                    {isDialogOpen && isDelete && (
                      <AlertDialog
                        question="Confirmación de Eliminación"
                        message={
                          confirmationMessages.DELETE_CONFIRMATION_OFICINA
                        }
                        params={[
                          {
                            name: "Oficina",
                            value:
                              selectedRow !== undefined
                                ? selectedRow[0].nombre
                                : "",
                          },
                        ]}
                        loading={loading}
                        isOpen={isDialogOpen}
                        onClose={handleCancel}
                        onAccept={() =>
                          handleConfirmationDelete(selectedRow[0].id)
                        }
                        onCancel={handleCancel}
                        render={"Delete"}
                      />
                    )}

                    <Tooltip title="Generar Reporte">
                      <button
                        className="action-button"
                        onClick={handleGenerateReport}
                      >
                        Generar Reporte
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
